<template>
	<section class="section section__videos section--larger">
		<div class="container">
			<h2 class="section__title has-text-centered">
				Videoclipuri {{ title }}
			</h2>
			<div class="columns is-multiline is-centered">
				<div
					class="column is-6 is-4-desktop"
					v-for="video in videos"
					:key="video.id"
				>
					<div class="video is-flex is-flex-direction-column">
						<div class="video__content is-relative mb-5">
							<iframe
								:src="video.url"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							></iframe>
						</div>
						<div
							class="is-flex is-flex-direction-column is-justify-content-space-between"
							style="height: 100%;"
						>
							<h3
								v-if="video.titlu"
								class="has-text-centered mb-3"
							>
								{{ video.titlu }}
							</h3>
							<h4 v-if="video.subtitlu" class="has-text-centered">
								{{ video.subtitlu }}
							</h4>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		props: ['videos', 'title'],
	};
</script>

<style lang="scss" scoped>
	.video {
		height: 100%;
		&__content {
			border-radius: $round-corners;
			padding-top: 56.25%;
			width: 100%;
		}
		iframe {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			min-width: 100%;
			height: 100%;
		}
		h3 {
			font-size: 1.25rem;
			text-transform: uppercase;
		}
		h4 {
			color: lighten($black, 25%);
			font-size: 1.125rem;
			font-weight: $medium;
		}
	}
</style>
