<template>
	<section class="section section--larger">
		<div class="container is-fluid">
			<h2 class="section__title has-text-centered">
				Galerie imagini
			</h2>
			<div class="columns is-multiline">
				<div class="column is-4" v-for="(img, i) in images" :key="i">
					<div class="image p-2" @click="index = i">
						<div class="image__inner is-relative">
							<img :src="img" alt="" />
							<div
								class="overlay is-flex is-align-items-center is-justify-content-center"
							>
								<ion-icon name="images"></ion-icon>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Tinybox
			:index="index"
			@change="
				(i) => {
					index = i;
				}
			"
			loop
			no-thumbs
			:images="images"
		/>
	</section>
</template>

<script>
	export default {
		props: ['images'],
		data() {
			return {
				index: null,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.image {
		background-color: $gray;
		&__inner {
			padding-top: 56.25%;
			width: 100%;
			overflow: hidden;
			&:hover {
				cursor: pointer;
				.overlay {
					opacity: 1;
				}
			}
			img {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				width: auto;
				height: 100%;
			}
			.overlay {
				background-color: rgba($black, 0.3);
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
				width: 100%;
				height: 100%;
				transition: opacity 0.3s ease;
			}
			ion-icon {
				color: $white;
				font-size: 2rem;
			}
		}
	}
</style>
